export const colors = {
  black: "#121212",
  white: "#ffffff",
  lightGrey: "#F2F2F2",
  lighterGrey: "#F5F5F5",
  mediumGrey: "#CDCDCD",
  primary: "#167CFB",
  imageBg: "#E6E6E6",
  notification: "#B5B5B5",
  text: "#ADADAD",
  background: "#fafafa",
  error: "#EA5E5E",
  lightgreen: "#15D128",
  catTitle: "#bf4141",
  grey: "#878787",
  border: "#D0D5DD",
  button: "#167CFB",
  focusColor: "#63a7fc",
  secondaryError: "#EA5E5E",
  orange: "#F63C3C",
  actionTextColor: "#B890FF",
  default: "#000000",
  brand: "#5308CC",
  brandText: "#B890FF",
  greyText: "#C4C4C4",
  defaultBorderColor: "#313234",
  card: "#232426",
  live: "#FF4A4A",
  date: "#ffffff99",
  platform: "#47484A",
  darkBackground: "#212123",
  blackBackground: "#161617",
  horizontalLine: "#C4C4C4",
  brand30Percent: "rgba(83, 8, 204, 0.3)",
  brand40Percent: "rgba(83, 8, 204, 0.4)",
  brand50Percent: "rgba(83, 8, 204, 0.5)",
  darkBlue: "#240D49",
  seprator: "#CACACA",
  lightblue: "#63a7fc",
  lighterblue: "#b9d7fd",
  veryLightBlue: "#f4f7fc",
  mediumblue: "#c4defe",
  mediumgrey: "#9e9e9e",
};
